<template>
    <div id="gpt-plugin" />
  </template>
  
  <script setup>
  import { onMounted } from 'vue';
  
  const GPT_CHATBOT_ID = '1382fb3c-e43c-4752-b425-341bb1608624';
  
  onMounted(() => {
    // Configure the GPT plugin
    window.gptbaseConfig = {
      chatbotId: GPT_CHATBOT_ID,
    };
  
    // Dynamically load the GPT script
    const scriptId = GPT_CHATBOT_ID;
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.src = 'https://gbase.ai/plugin/plugin.js';
      script.id = scriptId;
      script.defer = true;
      document.body.appendChild(script);
    }
  });
  </script>
  
  <style scoped>
  /* Add any styles specific to the GPT plugin here if needed */
  </style>
  